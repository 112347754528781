import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import Servizio from '../../components/Servizio'

import SafePlusImg from '../../images/safe_plus.jpg'

const paginaServizio = {
  titolo: 'Safe Plus',
  parent: null,
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: SafePlusImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Safe Plus è il prodotto per le tue spedizioni di valore. La merce si muove all’interno del circuito nazionale in aree riservate, accessibili esclusivamente a personale dedicato, e viene registrata in tutte le fasi operative del processo. In questo modo ti garantiamo, oltre alla consueta tracciabilità, anche un rigoroso monitoraggio.',
        },
        {
          id: 2,
          testo:
            'La consegna di colli fino a 25 kg, è effettuata in 24 ore nella penisola e 48 ore in Sicilia, Calabria e Sardegna.',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per collo',
          valore: '25 kg',
        },
        {
          id: 2,
          descrizione: 'Somma massima delle 3 dimensioni per collo',
          valore: '150 cm',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo:
            'Assicurazione All Risk fino ad un massimale di 2.500 € a collo',
        },
        {
          id: 2,
          testo:
            'Tempi di consegna regolari: 24 ore nella penisola; 48 ore in Sicilia, Calabria e Sardegna',
        },
        {
          id: 3,
          testo: 'Spedizioni multi-collo',
        },
        {
          id: 4,
          testo: 'Spedizione tracciata in ogni suo movimento',
        },
        {
          id: 5,
          testo:
            'Prenotazione della spedizione tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: null,
    },
  ],
}

const SafePlus = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default SafePlus
